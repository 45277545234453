.c-newfeatures {

    &__title {
        padding: 20px 0 5px;
        margin: 0;
        text-align: center;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 24px;
        color: $black;
        border-radius: 4px 4px 0 0;

        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__list {

        &--closed {
            display: none;

            & ~ .c-newfeatures {
                &__more {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__item {

        &:nth-of-type(2) {
            .c-newfeatures {
                &__num::before {
                    animation-delay: 0.5s;
                }
            }
        }

        &:nth-of-type(3) {
            .c-newfeatures {
                &__num::before {
                    animation-delay: 1s;
                }
            }
        }
    }

    &__caption {
        display: flex;
        align-items: center;
        padding: 25px 0;
    }

    &__num {
        position: relative;
        width: 52px;
        height: 52px;
        flex-basis: 52px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 25px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 27px;
        line-height: 50px;
        color: $breakerbay;
        text-align: center;

        &::before {
            position: absolute;
            content: '';
            width: 52px;
            height: 52px;
            top: 0;
            left: 0;
            background: url('../img/general/spinner.png') no-repeat center;
            background-size: 100% 100%;
            animation: spinner 1.5s linear infinite;
        }
    }

    &__text {
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 18px;
        color: $black;
    }

    &__wrap {
        position: relative;

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 15px 0 15px;
            border-color: $white transparent transparent transparent;
        }

        img {
            max-width: 100%;
            width: 100%;
            height: auto;
        }
    }

    &__more {
        display: block;
        margin-top: 25px;
    }
}

@keyframes spinner {
    from { transform: rotate(0);}
    to { transform: rotate(360deg); }
  }

@media(min-width: $tablet) {
    .c-newfeatures {
        padding-top: 55px;

        &__title {
            margin-bottom: 30px;
            font-size: 32px;
        }

        &__item {
            display: flex;

            &:nth-of-type(2n+1) {
                .c-newfeatures__wrap {
                    &::before {
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        border-width: 15px 0 15px 12px;
                        border-color: transparent transparent transparent $white;
                    }
                }
            }

            &:nth-of-type(2n) {
                .c-newfeatures{
                    &__wrap {
                        order: -1;

                        &::before {
                            top: 50%;
                            left: auto;
                            right: 0;
                            transform: translateY(-50%);
                            border-width: 15px 12px 15px 0;
                            border-color: transparent $white transparent transparent;
                        }
                    }
                }
            }

        }

        &__caption,
        &__wrap {
            flex-basis: 50%;
        }

        &__caption {
            flex-direction: column;
        }

        &__num {
            margin-top: auto;
            margin-right: 0;
            margin-bottom: 25px;
        }

        &__text {
            margin-bottom: auto;
        }
    }
}

@media(min-width: $desctop) {
    .c-newfeatures {
        padding-top: 30px;

        &__title {
            font-size: 40px;
        }

        &__list {
            display: grid;
            grid-template-areas: "a b" "a c";
            grid-template-columns: [col] 1fr [col] 2fr [col];
            grid-template-rows: [row] 300px [row] 300px;
        }

        &__item {
            &:nth-of-type(1) {
                grid-row: row / span 2;
                flex-direction: column;

                .c-newfeatures__wrap::before {
                    border-width: 12px 15px 0 15px;
                    border-color: $white transparent transparent transparent;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}