.c-modal {

    &__help {
        display: block;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 12px;
        color: #819999;
        margin-top: 15px;
        text-align: center;
        //max-width: 80%;
        margin: 15px auto 0;

        .icon {
            margin-right: 10px;
        }
    }

    &__subtitle {
        max-width: 230px;
        margin: 0 auto 30px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 15px;
        color: $mineshaft;
        text-align: center;
    }

    .modal {

        &-content {
            border: none;
            border-radius: 0;
            max-width: 310px;
            margin: 0 auto;
        }

        &-header {
            padding: 18px;
            background: $breakerbay;
            border-radius: 0;

            .close {
                position: absolute;
                top: 0;
                right: 15px;
                transform: translateY(-100%);
                padding: 0;
            }
        }

        &-title {
            flex-grow: 1;
            font-family: $main-font-bold, Arial, sans-serif;
            font-size: 16px;
            color: $white;
            text-transform: uppercase;
            text-align: center;
        }

        &-body {
            padding: 26px 19px 20px;
        }
    }
}