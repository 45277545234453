.c-choose-us {
    padding-top: 24px;
    padding-bottom: 30px;
    border-bottom: 1px solid $mystic;

    &__title {
        position: relative;
        padding: 0 0 45px;
        margin: 0;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 25px;
        color: $black;
        text-align: left;

        &::after {
            position: absolute;
            content: '';
            bottom: 20px;
            left: 0;
            width: 46px;
            height: 2px;
            background: $persiangreen;
        }

        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 19px 20px 15px 10px;
        background: $mystic;
        border-bottom: 1px solid $geyser;
        cursor: pointer;

        &--active {
            position: relative;
            border-bottom: none;
            cursor: default;

            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 50%;
                transform: translateX(-50%) translateY(100%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 4.5px 0 4.5px;
                border-color: $mystic transparent transparent transparent;
            }

            .c-choose-us__switcher {
                transform: none;
            }
        }
    }

    &__icon {
        flex-basis: 58px;
        flex-shrink: 0;
    }

    &__subtitle {
        font-family: $main-font, Arial, sans-serif;
        font-size: 15px;
        color: $black;
        flex-grow: 1;
        margin-bottom: 0;
    }

    &__switcher {
        margin-left: 10px;
        transform: rotate(180deg);
        cursor: pointer;
    }

    &__body {
        display: none;
        padding: 0 10px 10px;
        background: $blacksqueeze;

        &-title {
            margin: 0;
            padding: 25px 0 10px;
            font-family:$main-font, arial, sans-serif;
            font-size: 19px;
            color: $black;
        }

        &-text {
            font-family:$main-font-light, arial, sans-serif;
            font-size: 15px;
            color: $black;
        }

        &--opened {
            display: block;
        }
    }

    &__list {
        margin: 0 -10px;
    }
}

@media(min-width: $tablet) {
    .c-choose-us {
        padding: 35px 0;
        background: $blacksqueeze;

        &__list {
            margin: 0;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: repeat(5, 1fr);
        }

        &__header {
            background: none;
            border-bottom: none;

            &::after {
                content: none;
            }

            &--active {
                background: $mystic;

                &::after {
                    content: '';
                    bottom: auto;
                    left: auto;
                    right: -5px;
                    top: 50%;
                    z-index: 99;
                    transform: translateY(-50%);
                    border-width: 4.5px 0 4.5px 5px;
                    border-color: transparent transparent transparent $mystic;
                }
            }
        }

        &__body {

            &-title {
                padding: 0 0 20px;
                font-size: 24px;
            }

            &--opened {
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 7;
                z-index: 2;
            }
        }

        &__switcher {
            display: none;
        }
    }
}

@media(min-width: $desctop) {
    .c-choose-us {
        padding: 95px 0;
        margin: 0 auto;
        max-width: 1340px;

        &__title {
            margin-bottom: 37px;
            font-size: 35px;
        }

        &__body {
            padding-left: 90px;

            &-title {
                padding-bottom: 25px;
                font-size: 25px;
            }

            &-text {
                font-size: 16px;
            }
        }

        &__list {
            grid-template-columns: 515px 1fr;
        }
    }
}