.c-available {

    .c-comfortable {

        &__title {
            margin-bottom: 10px;
        }

        &__video {
            margin-bottom: 25px;
            padding-top: 0;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__img {
            position: relative;
            margin-bottom: 21px;
            border-radius: 5px;
            overflow: hidden;

            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(20, 52, 53, 0.3);
            }

            img {
                max-width: 100%;
                width: 100%;
                height: auto;
            }
        }
    }

    .c-button {
        display: block;
        min-width: 170px;
        padding: 27px 50px;
        margin-right: 0;
        margin-bottom: 19px;
        font-size: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        svg {
            margin-left: 12px;
        }
    }
}

@media(min-width: $tablet) {
    .c-available {

        .c-button {
            display: inline-block;
        }

        .c-comfortable {
            &__title {
                margin-bottom: 30px;
                padding-top: 47px;
            }

            &__video {
                margin-bottom: -40px;
            }

            &__download {
                text-align: center;
            }

            &__img {
                margin-bottom: -40px;
                transform: scale(-1, 1);
                order: 1;
            }

            &__wrapper {
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 71px;

                .c-button {
                    margin-bottom: 0;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-available {
        position: relative;
        background: #137981 url('../img/content/all-work-big.jpg') no-repeat left bottom / auto 100%;

        /*&::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(22,138,139,1) 40%, rgba(22,138,139,1) 100%);
        }*/

        .c-container {
            /*grid-template-areas: "a b" "c b";
            grid-template-columns: [col] 1fr [col] 630px [col];
            grid-template-rows: [row] auto [row];*/
            position: relative;
            grid-template-areas:
                "a b"
                "a c"
                "a d";
            grid-template-rows: [row] auto [row] auto [row];
            grid-template-columns: [col] 1fr [col] 533px [col];
        }

        .c-comfortable {

            &__img {
                display: none;
            }

            &__title {
                grid-area: b;
            }

            &__paragraph {
                grid-area: c;
            }

            &__wrapper {
                grid-area: d;
            }

            &__video {
                grid-column: 2;
                margin-bottom: 50px;
            }

            &__download {
                text-align: left;
            }
        }
    }
}

/*@media(min-width: 1600px) {
    .c-available {
        &::before {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.01) 0%, #168a8b 33%, #168a8b 100%);
        }
    }
}*/