/* F O N T S */
@import 'utils/fonts.scss';

/* V A R S */
@import 'utils/vars.scss';

/* M I X I N */
@import 'utils/mixins.scss';

/* L I B R A R Y */
@import 'libs.scss';

/* G R I D */
//@import 'utils/grid.scss';

/* I C O N S */
@import 'utils/icons.scss';

/* B U T T O N S */
@import 'utils/buttons.scss';

/* M O D A L S */
@import 'utils/modals.scss';

/* F O R M S */
@import 'utils/form.scss';


html {
	font-size: $baseFontSize;
	line-height: 1.35;
}


body {
	margin: 0;
    width: 100%;
    max-width: 100%;
	font-family: $main-font, Arial, sans-serif;
	font-size: 15px;
	color: $black;
	background: $blacksqueeze;
}

.c-container {
    max-width: $desctop;
	width: calc(100% - 20px);
	margin: 0 auto;
	box-sizing: border-box;
}

/** ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */
.c-container .table-bordered {
	border: none;
  }
  
  @media only screen and (max-width: 768px) {
	.c-container-filter td{
	  width: 100%;
	}
	.c-contacts {
	  padding-left: 10px;
	  padding-right: 10px;
	}
  }
/** end ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */

main {
	padding-top: 59px;
}

.alert {
	position: fixed;
	top: 79px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 200;
    white-space: nowrap;
}
/* ------- common style ------- */
ul {
	@include list;
}

a {
	@include link;
}
.modal-video-inner.vertical {
	.modal-video-movie-wrap {
		padding-bottom: 150% !important;
	}
}



// Style for text
.c-text {
	font-family: $main-font, Arial, sans-serif;

	&--medium {
		font-family: $main-font-medium, Arial, sans-serif;
	}

	&--semi {
		font-family: $main-font-semi, Arial, sans-serif;
	}

	&--bold {
		font-family: $main-font-bold, Arial, sans-serif;
	}

	&--light {
		font-family: $main-font-light, Arial, sans-serif;
	}

	&--align {
		&-right {
			text-align: right;
		}

		&-center {
			text-align: center;
		}
	}

	&--size {

		&-s12-auto {
			font-size: 12px;
			line-height: 1.2;
		}
		&-s14-auto {
			font-size: 14px;
			line-height: 1.2;
		}

		&-s16-auto {
			font-size: 16px;
			line-height: 1.2;
		}

		&-s16-h16 {
			font-size: 16px;
			line-height: 16px;
		}

		&-s16-h21 {
			font-size: 16px;
			line-height: 21px;
		}

		&-s18-h22 {
			font-size: 18px;
			line-height: 22px;
		}
  	}

 	&--color {
		&-white {
			color: $white;
		}

		&-black {
			color: $black;
		}

		&-red {
			color: $danger;
		}

		&-persiangreen {
			color: $persiangreen;
		}

		&-emperor {
			color: $emperor;
		}
	}

	&--transform {
		&-upper {
			text-transform: uppercase;
		}
	}
}

// Hidden
.c-hidden {
 	display: none !important;
}

// Link
.c-link {
	font-size: 12px;
	line-height: normal;
	//color: $curiousblue;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&--button {
		padding: 8px 12px;
		font-family: $main-font-medium, Arial, sans-serif;
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		//color: $manatee;
		background: $white;
		//border: 1px solid $athensgray;
		box-sizing: border-box;
		border-radius: 4px;

		&.active {
			//border-color: $curiousblue;
		}
	}

	&--gray {
		font-size: 16px;
		line-height: 21px;
		//color: $gullgray;
	}
}

// stub
.c-stub {
	display: flex;
	height: 100vh;

	&__text {
		margin: auto
	}
}

// modules
@import "modules/modules.scss";

// Index Page
@import "pages/index/index.scss";

@media (min-width: $tablet) {
	.c-container {
		width: calc(100% - 40px);
	}
}

/** ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */
.validate-error {
	border-color: #f29696;
  }
  /** end ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */

/* перебьем стили bootstrap*/
*:focus,
button:focus,
.dataTable.display tbody td:focus {outline: none;}
/* end перебьем стили bootstrap*/