@media (max-width: 1365px) {
	
}

@media(max-width: 1023px) {

}

@media (max-width: 719px) {

}