.c-reliability {
    padding-bottom: 25px;
    border-bottom: 1px solid $mystic;

    &__title {
        position: relative;
        padding-bottom: 20px;
        margin: 0 0 16px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 25px;
        color: $black;
        text-align: left;

        &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 46px;
            height: 2px;
            background: $persiangreen;
        }

        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        position: relative;
        padding-left: 32px;
        margin-bottom: 9px;
        font-family: $main-font, Arial, sans-serif;
        font-size: 16px;
        color: $mineshaft;

        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 5px;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: $porceline url('../img/general/check.png') no-repeat top 2px left 4px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media(min-width: $tablet) {
    .c-reliability {
        padding: 0 0 40px;
        background: url('../img/content/reliability.jpg') no-repeat bottom 40px right 0/ 41%;
        border-bottom: none;

        &__title {
            margin-bottom: 30px;
            font-size: 32px;
        }

        &__list {
            //max-width: 60%;
        }

        &__item {
            margin-bottom: 14px;
            max-width: 380px;

            &:first-of-type {
                max-width: 450px;
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-reliability {
        padding: 0 0 220px;
        //background-size: auto 90%;
        background: url('../img/content/reliability.jpg') no-repeat bottom 98px left calc(50% + 300px)/ 600px 442px;

        &__title {
            margin-bottom: 55px;
            font-size: 35px;
        }

        &__list {
            max-width: 50%;
        }

        &__item {
            margin-bottom: 24px;
            max-width: 100%;

            &:first-of-type {
                max-width: 100%;
            }
        }
    }
}