.c-personalarea {
    padding-bottom: 20px;

    &__title {
        position: relative;
        padding: 20px 0;
        margin: 0 0 25px;
        text-align: center;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 24px;
        color: $black;
        border-radius: 4px 4px 0 0;

        &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 46px;
            height: 2px;
            background: $persiangreen;
        }

        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__list {

    }

    &__item {
        display: flex;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        flex-basis: 36px;
        flex-shrink: 0;
        margin-right: 20px;
        width: 36px;
        height: 36px;
        background: $persiangreen url('../img/general/check-white.png') no-repeat center;
        border-radius: 50%;
    }

    &__wrap {

    }

    &__text {
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 15px;
        margin-bottom: 0;

        * {
            margin-right: 5px;
            font-family: $main-font-bold, Arial, sans-serif;
            font-weight: 400;
        }
    }
}

@media(min-width: $tablet) {
    .c-personalarea {

        &__title {
            margin-bottom: 35px;
            font-size: 32px;
        }

        &__list {
            display: flex;
            justify-content: space-between;
        }

        &__item {
            flex-basis: calc((100% - 100px)/3);
            flex-direction: column;
            align-items: center;
            max-width: 300px;
            text-align: center;
        }

        &__icon {
            width: 63px;
            height: 63px;
            flex-basis: 63px;
            margin-right: 0;
            margin-bottom: 25px;
        }

        &__text {
            font-size: 16px;
        }
    }
}

@media(min-width: $desctop) {
    .c-personalarea {
        margin-bottom: 70px;

        &__title {
            font-size: 35px;
        }

        &__text {
            font-size: 18px;
        }
    }
}