// Шапка
@import 'header/header.scss';

// Карусель
@import 'carousel/carousel.scss';



// Типы грузов (аккордион)
@import 'accordion/accordion.scss';

// Мы сделали
@import 'done/done.scss';

// Надежный партнер
@import 'reliability/reliability.scss';

// Почему выбирают нас
@import 'choose-us/choose-us.scss';

// Наша работа в числах
@import 'numeric-statistics/numeric-statistics.scss';

// Нам доверяют
@import 'trust-us/trust-us.scss';




// Мобильный помошник
@import 'mobile-assistant/mobile-assistant.scss';

// Невероятно удобно
@import 'comfortable/comfortable.scss';

// Используйте мобильное приложение
@import 'use-mobile-app/use-mobile-app.scss';

// Личный кабинет
@import 'personal-area/personal-area.scss';

// Доступно. Прозрачно. Информативно.
@import 'available/available.scss';

// Получить новые возможности
@import 'new-features/new-features.scss';

// Наши контакты
@import 'contacts/contacts.scss';

// Футер
@import 'footer/footer.scss';

// Модальное окно
@import 'modals/modals.scss';

@import 'current-applications/current-applications.scss';
