.c-types-cargo {
    position: relative;
    z-index: 20;
    margin-top: -36px;
    margin-bottom: 30px;

    &__item {
        margin-bottom: 11px;
        background: $blacksqueeze;
        box-shadow: 0px 0px 25px 0px rgba(197, 204, 203, 0.4);

        &:nth-of-type(2n+1) {
            .c-types-cargo {
                &__header {
                    border-color: $brightturquoise;
                }
            }
        }

        &:nth-of-type(2n) {
            .c-types-cargo {
                &__header {
                    border-color: $malachite;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--closed {
            .c-types-cargo {
                &__switcher {
                    transform: rotate(180deg);
                }

                &__list,
                &__action {
                    display: none;
                }
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 19px 20px 15px 10px;
        border-top: 6px solid;
    }

    &__icon {
        flex-basis: 46px;
    }

    &__title {
        font-family: $main-font-semi, Arial, sans-serif;
        font-size: 17px;
        flex-grow: 1;
        margin-bottom: 0;
    }

    &__switcher {
        margin-left: 20px;
        cursor: pointer;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0 20px 20px 10px;

        &-item {
            position: relative;
            padding-left: 15px;
            margin-bottom: 10px;
            font-family: $main-font, Arial, sans-serif;
            font-size: 15px;
            color: $cuttysark;

            &::before {
                position: absolute;
                content: '';
                top: 9px;
                left: 0;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $ziggurat;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__action {
        margin: 0 10px;
        padding-bottom: 10px;
    }
}

@media(min-width: $tablet) {
    .c-types-cargo {
        margin-top: -60px;
        margin-bottom: 35px;

        &__item {
            margin-bottom: 20px;
        }

        &__header {
            border-width: 10px;
            padding: 29px 45px 25px;
        }

        &__title {
            font-size: 22px;
        }

        &__switcher {
            display: none;
        }

        &__list {
            padding: 0 45px 30px 45px;

            &-item {
                padding-left: 20px;
                margin-bottom: 8px;
                font-size: 16px;
            }
        }

        &__action {
            padding-bottom: 45px;
            margin-left: 45px;
        }
    }
}

@media(min-width: $desctop) {
    .c-types-cargo {
        margin-bottom: 72px;

        &__item {
            width: calc(50% - 20px);
            margin-bottom: 0;
        }

        &__action {
            padding-top: 55px;
        }

        .c-container {
            display: flex;
            justify-content: space-between;
        }
    }
}