.c-assistant {
    position: relative;
    z-index: 20;
    margin-top: -36px;
    margin-bottom: 25px;

    &__title {
        position: relative;
        padding: 20px;
        margin: 0;
        text-align: center;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 21px;
        color: $black;
        background: $white;
        border-radius: 4px 4px 0 0;

        &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 46px;
            height: 2px;
            background: $persiangreen;
        }
        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__list {
        padding: 25px 0;

        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--closed {
            & ~ .c-assistant {
                &__more {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            .c-assistant {
                &__list {
                    &-item {
                        &:nth-of-type(3) {
                            & ~ .c-assistant__list-item {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__icon {
        display: flex;
        flex-basis: 59px;
        flex-shrink: 0;
        flex-grow: 0;
        padding: 0 5px;
        margin-right: 5px;

        img {
            margin: auto;
        }
    }

    &__text {
        flex-grow: 1;
        margin: 0;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 14px;

        span {
            margin-right: 5px;
            font-family: $main-font-bold, Arial, sans-serif;
        }

        br {
            display: none;
        }
    }

    &__more {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &--app {
        margin-bottom: 35px;

        .c-assistant {
            &__title {
                padding: 12px 8px 23px;
                font-family: $main-font-light, Arial, sans-serif;
                font-size: 25px;
                background: $blacksqueeze;
            }

            &__list {
                padding: 37px 0 0;

                &-item {
                    margin-bottom: 35px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            &__text {
                font-size: 18px;

                br {
                    display: block;
                }
            }

            &__icon {
                flex-basis: 75px;

                img {
                    height: 62px;
                    width: auto;
                }
            }

            &__more {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin: 35px auto 0;
                padding: 10px 20px;
                min-height: 40px;
                margin-top: 35px;
                box-shadow: none;
                cursor: pointer;
            }

            &__list--closed ~ .c-assistant__more {
                margin-top: 0;
            }
        }
    }

    &--private_office {
        padding-bottom: 35px;
        margin-bottom: 0;
        //box-shadow: 0px 0px 25px 0px rgba(197, 204, 203, 0.4);

        .c-assistant {
            &__title {
                background: $blacksqueeze;
                padding: 10px 40px 16px;
                font-family: $main-font-light, Arial, sans-serif;
                font-size: 25px;
                line-height: 35px;
            }

            &__text {
                font-size: 18px;
                line-height: normal;
            }

            &__img {

            }

            &__list {
                padding: 37px 0 0;
            }

            &__list-item {
                margin-bottom: 35px;

                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6) {

                    .c-assistant__img {
                        width: auto;
                        max-height: 62px;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &__icon {
                flex-basis: 84px;
                flex-grow: 0;
                flex-shrink: 0;
                margin-right: 18px;
            }

            &__more {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin: 35px auto 0;
                padding: 10px 20px;
                min-height: 40px;
                cursor: pointer;
                box-shadow: none;
            }

            &__list--closed ~ .c-assistant__more {
                margin-top: 35px;
            }
        }
    }
}

@media(min-width: $tablet) {
    .c-assistant {

        &__title {
            font-size: 32px;
        }

        &__list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &-item {
                flex-basis: calc(50% - 25px);
                flex-shrink: 0;
                flex-grow: 0;
                margin-bottom: 50px;

                &:nth-of-type(1) {
                    img {
                        width: 37px;
                        height: 61px;
                    }
                }

                &:nth-of-type(2) {
                    img {
                        width: 62px;
                        height: 61px;
                    }
                }

                &:nth-of-type(3),
                &:nth-of-type(4) {
                    img {
                        width: 36px;
                        height: 61px;
                    }
                }

                &:nth-of-type(5) {
                    img {
                        width: 48px;
                        height: 60px;
                    }
                }

                &:nth-of-type(6) {
                    img {
                        width: 60px;
                        height: 61px;
                    }
                }

                &:nth-last-of-type(1),
                &:nth-last-of-type(2) {
                    margin-bottom: 0;
                }
            }
        }

        &__more {
            display: none;
        }

        &--app {
            .c-assistant {
                &__title {
                    padding: 24px 60px 10px;
                    font-size: 34px;
                    line-height: 44px;
                }

                &__list {
                    &-item {
                        margin-bottom: 50px;

                        &:nth-of-type(5) {
                            order: 1;

                            img {
                                width: auto;
                                height: 62px;
                            }
                        }

                        &:last-of-type {
                            br {
                                display: none;
                            }
                        }

                        &:nth-last-of-type(1),
                        &:nth-last-of-type(2) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &--private_office {
            padding-bottom: 0;
            margin-bottom: 39px;
            margin-top: -61px;

            .c-assistant {
                &__title {
                    padding-top: 24px;
                    font-size: 34px;
                }

                &__list-item {
                    flex-basis: 50%;
                    max-width: 303px;
                    margin-bottom: 50px;

                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5),
                    &:nth-of-type(6) {

                        .c-assistant__img,
                        .c-assistant__img--hover {
                            width: 60px;
                            height: auto;
                        }
                    }

                    &:nth-last-of-type(1),
                    &:nth-last-of-type(2) {
                        margin-bottom: 0;
                    }
                }

                &__text {
                    max-width: 194px;
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-assistant {

        &__title {
            font-size: 35px;
        }

        &__list {

            &-item {
                flex-basis: calc((100% - 100px)/3);
                display: block;
            }
        }

        &__icon {
            margin-right: 0;
            margin-bottom: 35px;
        }

        &__text {
            max-width: 330px;
            margin: 0 auto;
            text-align: center;

            br {
                display: block;
            }
        }

        &--app,
        &--private_office {
            margin-bottom: 178px;
            box-shadow: none;

            .c-assistant {
                &__title {
                    padding-top: 70px;
                    padding-bottom: 43px;
                    font-size: 40px;
                }

                &__list {
                    padding-top: 85px;

                    &-item {
                        flex-basis: 30%;
                        max-width: 30%;

                        &:nth-of-type(1),
                        &:nth-of-type(2),
                        &:nth-of-type(3),
                        &:nth-of-type(4),
                        &:nth-of-type(5),
                        &:nth-of-type(6) {

                            .c-assistant__img {
                                max-width: 60px;
                                width: auto;
                            }
                        }

                        &:nth-of-type(1),
                        &:nth-of-type(2),
                        &:nth-of-type(3) {
                            margin-bottom: 72px;
                        }

                        &:nth-of-type(4),
                        &:nth-of-type(5),
                        &:nth-of-type(6) {
                            margin-bottom: 0;
                        }
                    }
                }

                &__text {
                    max-width: 100%;
                    //font-size: 24px;
                }

                &__icon {
                    margin-right: 0;
                }
            }
        }

        &--app {
            .c-assistant {
                &__title {
                    padding-top: 65px;
                }
            }
        }

        &--private_office {
            .c-assistant {

                &__list {

                    &-item {

                        &:nth-of-type(2) {
                            .c-assistant {
                                &__text {
                                    max-width: 100%;
                                }
                            }
                        }

                        &:nth-of-type(3) {
                            .c-assistant {
                                &__text {
                                    max-width: 65%;
                                }
                            }
                        }
                    }
                }

                &__text {
                    max-width: 85%;
                }
            }
        }
    }
}

/** ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */
.dataTables_wrapper {
    width: 100%;

    table tbody tr td {
        text-align: center;
        vertical-align: middle;
    }
}
/** end ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */