.c-trust-us {
    padding-top: 24px;
    padding-bottom: 25px;
    border-bottom: 1px solid $mystic;

    &__title {
        position: relative;
        padding: 0 0 57px;
        margin: 0;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 25px;
        color: $black;

        &::after {
            position: absolute;
            content: '';
            bottom: 40px;
            left: 0;
            width: 46px;
            height: 2px;
            background: $persiangreen;
        }

        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 0 0 23px;
        padding: 0;
        list-stile-type: none;
    }

    &__item {
        display: flex;
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        height: 150px;
        overflow: hidden;
        background: $blacksqueeze;

        &.c-trust-us__item1,
        &.c-trust-us__item4,
        &.c-trust-us__item5,
        &.c-trust-us__item8,
        &.c-trust-us__item9,
        &.c-trust-us__item12 {
            background: $aquahaze;
        }
    }

    &__link {
        display: flex;
        width: 100%;
    }

    &__img {
        max-width: calc(100% - 20px);
        height: auto;
        margin: auto;
    }

    &__all {
        font-family: $main-font, Arial, sans-serif;
        font-size: 15px;
        color: $persiangreen;
        border-bottom: 1px dotted $persiangreen;
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        margin: 0 auto;
        cursor: pointer;

        span {
            margin-right: 5px;
        }
    }

    &--cargo-owners {
        padding-top: 0;
        border-bottom: none;

        .c-trust-us {
            &__title {
                position: relative;
                padding: 26px 0 15px;
                margin-bottom: 32px;
                font-family: "Exo2-Light", Arial, sans-serif;
                font-weight: normal;
                font-size: 25px;
                line-height: 35px;
                text-align: left;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: 46px;
                    height: 2px;
                    background: $persiangreen;
                }
            }
        }
    }
}

@media(min-width: $tablet) {
    .c-trust-us {
        padding-top: 34px;

        &__title {
            padding: 0 0 67px;
            font-size: 32px;
        }

        &__item {
            flex-basis: 33.33%;
            height: 243px;

            &.c-trust-us__item4,
            &.c-trust-us__item8,
            &.c-trust-us__item12 {
                background: $blacksqueeze;
            }

            &.c-trust-us__item1,
            &.c-trust-us__item3,
            &.c-trust-us__item5,
            &.c-trust-us__item7,
            &.c-trust-us__item9,
            &.c-trust-us__item11 {
                background: $aquahaze;
            }
        }

        &--cargo-owners {
            .c-trust-us {
                &__title {
                    padding-top: 21px;
                    margin-bottom: 38px;
                    font-size: 32px;
                    line-height: normal;
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-trust-us {
        padding-top: 84px;
        padding-bottom: 0;
        border: none;

        &__title {
            padding: 0 0 67px;
            font-size: 35px;

            &::after {
                bottom: 40px;
            }
        }

        &__list {
            margin-bottom: 0;
        }

        &__item {
            flex-basis: 25%;
            height: 300px;

            &.c-trust-us__item4,
            &.c-trust-us__item5,
            &.c-trust-us__item7,
            &.c-trust-us__item12 {
                background: $blacksqueeze;
            }

            &.c-trust-us__item1,
            &.c-trust-us__item3,
            &.c-trust-us__item6,
            &.c-trust-us__item8,
            &.c-trust-us__item9,
            &.c-trust-us__item11 {
                background: $aquahaze;
            }
        }

        &--cargo-owners {
            padding-top: 94px;

            .c-trust-us {
                &__title {
                    margin-bottom: 82px;
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        }
    }
}