.c-button {
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 17px 50px;
    min-height: 46px;
    min-width: 150px;
    font-family: $main-font-medium, Arial, sans-serif;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: $englishholly;
    background: $blacksqueeze;
    box-shadow: 0px 3px 12px 0px rgba(9, 95, 96, 0.25);
    border: none;
    border-radius: 4px;
    outline: none;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
        color: $persiangreen;
        background: $swansdown;
    }

    .icon {
        fill: $white;
        margin-left: 16px;
        transition: all 0.5s ease;
    }

    &--transparent {
        background: transparent;
        border: none;
        padding: 0 !important;
        min-height: 0;

        &:hover {
            background: transparent;
            border: none;
        }
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--primary {
        font-family: $main-font-bold, Arial, sans-serif;
        color: $white;
        background: $malachite;
        text-shadow: 0px 1px 0px rgba(21, 150, 58, 0.3);
        box-shadow: 0px 1px 0px 0px rgba(30, 162, 68, 1),
                    0px 3px 5px 0px rgba(0, 0, 0, 0.17);

        &:hover {
            color: $white;
        }
    }

    &--light {
        padding-top: 14px;
        padding-bottom: 14px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 14px;
        color: $breakerbay;
        border: 1px solid $breakerbay;

        .icon {
            fill: $breakerbay;
        }
    }
}

@media (max-width: 719px) {
    .c-button {
        
    }
}