.c-done {

    &__wrapper {
        position: relative;
        background: url('../img/content/done.jpg') no-repeat center / cover;
        padding-top: 63.75%;

        &::before {
            content: '';
            position: absolute;
            top: 101%;
            left: 0;
            right: 0;
            padding-bottom: 63.75%;
            background: linear-gradient(to right,rgb(28, 166, 168) 0%,rgb(33, 131, 161) 100%);
            clip-path: polygon(100% 86%, 0% 100%, 100% 100%);
            transform: translateY(-100%);
        }
    }

    &__img {
        max-width: 100%;
        height: auto;
    }

    &__block {
        padding: 10px 0 30px;
        text-align: center;
        background-color: #1f95a5;
        /* IE9, iOS 3.2+ */
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMWNhNmE4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMyMTgzYTEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3ZzZ2cpIiAvPjwvc3ZnPg==);
        background-image: -webkit-gradient(linear, 0% 0%, 100% 0%,color-stop(0, rgb(28, 166, 168)),color-stop(1, rgb(33, 131, 161)));
        /* Android 2.3 */
        background-image: -webkit-linear-gradient(left,rgb(28, 166, 168) 0%,rgb(33, 131, 161) 100%);
        /* IE10+ */
        background-image: linear-gradient(to right,rgb(28, 166, 168) 0%,rgb(33, 131, 161) 100%);
        background-image: -ms-linear-gradient(left,rgb(28, 166, 168) 0%,rgb(33, 131, 161) 100%);
    }

    &__title {
        position: relative;
        padding: 0 0 35px;
        margin: 0;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 25px;
        color: $white;

        &::after {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            width: 35px;
            height: 3px;
            background: $white;
        }
    }

    &__text {
        margin-bottom: 20px;
        font-family: $main-font, Arial, sans-serif;
        font-size: 16px;
        color: $white;
    }

    &__button {
        display: block;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            svg {
                fill: $persiangreen;
            }
        }

        span {
            margin-right: 8px;
        }

        svg {

        }
    }
}

@media(min-width: $tablet) {
    .c-done {
        display: flex;
        height: 315px;
        overflow: hidden;

        &__wrapper,
        &__block {
            flex-basis: 50%;
            flex-shrink: 0;
            flex-grow: 0;
        }

        &__wrapper {
            padding-top: 23.75%;
            height: 315px;
            background-size: cover;

            &::before {
                top: 315px;
                right: -1px;
                padding-bottom: 315px;
                clip-path: polygon(86% 0, 100% 0, 100% 100%);
                background: #1ca6a8;
            }
        }

        &__block {
            padding-top: 34px;
            text-align: left;
        }

        &__title {
            padding-bottom: 45px;
            font-size: 32px;

            &::after {
                width: 43px;
                height: 4px;
                left: 0;
                transform: none;
            }
        }

        &__text {
            max-width: 345px;
            font-size: 17px;
        }

        &__button {
            display: inline-block;
            vertical-align: middle;
            margin: 0 8px 0 0;
            padding-left: 12px;
            padding-right: 12px;
            font-size: 12px;

            &:last-child {
                margin-right: 0;
            }
        }

        .c-container {
            margin: 0 10px;
            width: calc(100% - 20px);
        }
    }
}

@media(min-width: 860px) {
    .c-done {

        &__button {
            font-size: 15px;
        }
    }
}

@media(min-width: $desctop) {
    .c-done {
        height: 396px;

        &__wrapper {
            flex-basis: 38.125%;
            padding-top: 20%;
            height: 396px;
            background-size: cover;

            &::before {
                top: 396px;
                padding-bottom: 396px;
            }
        }

        &__block {
            flex-grow: 1;
            padding-top: 60px;
        }

        &__title {
            font-size: 36px;
        }

        &__text {
            max-width: 100%;
            margin-bottom: 26px;
            font-size: 20px;
        }

        &__button {
            padding-right: 30px;
            padding-left: 30px;
        }

        .c-container {
            max-width: 928px;
        }
    }
}