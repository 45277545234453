.c-footer {
    background: $aquahaze;

    &__logo {
        display: block;
        padding: 22px 25px;
        text-align: center;
        border-bottom: 1px solid $mystic;
    }

    &__phones {
        padding: 20px 0 22px;
    }

    &__phone {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        * {
            font-family: $main-font, Arial, sans-serif;
            font-size: 16px;
            color: $black;
        }
    }

    &__icon {
        display: flex;
        width: 29px;
        height: 29px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid $breakerbay;

        svg {
            margin: auto;
            fill: $breakerbay;
        }
    }

    &__copyright {
        padding: 17px 0;
        text-align: center;
        background: $geyser;
    }

    &__copyright-text {
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 14px;
        color: $mineshaft;
    }

    &__mail {
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 14px;
        color: $breakerbay;
    }
}

@media(max-width: $max-mobile) {
    .c-footer {

        .c-container {
            width: 100%;
        }
    }
}

@media (min-width: $tablet) {
    .c-footer {
        padding: 37px 0;

        &__logo {
            padding: 0;
            margin-bottom: 10px;
            border: none;
            grid-area: a;
            text-align: left;

            img {
                width: 181px;
                height: 33px;
            }
        }

        &__phones {
            display: flex;
            padding: 0;
            grid-area: b;
        }

        &__phone {
            margin-bottom: 0;
            margin-right: 60px;

            &:last-child {
                margin-right: 0;
            }
        }

        &__copyright {
            flex-grow: 1;
            width: 100%;
            padding: 0;
            text-align: left;
            background: none;
            grid-area: c;
        }

        .c-container {
            /*display: flex;
            justify-content: space-between;
            flex-wrap: wrap;*/
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto;
            grid-template-areas:
                'a b'
                'c b';
        }
    }
}

@media (min-width: $desctop) {
    .c-footer {

        &__logo {
            margin-right: 50px;
            margin-bottom: 0;
        }

        &__phones {
            order: 1;
        }

        &__copyright {
            flex-grow: 1;
            width: auto;
        }

        .c-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
        }
    }
}