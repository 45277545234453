.c-num-statistics {
    padding-top: 28px;
    padding-bottom: 70px;
    background: $aquahaze;
    border-bottom: 1px solid $mystic;

    &__header {

    }

    &__title {
        padding: 0 0 24px;
        margin: 0;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 25px;
        color: $black;
        text-align: center;

        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__body {
        position: relative;
        display: grid;
        grid-template-columns: [col] 1fr [col] 1fr [col];
        grid-template-rows: [row] auto [row];

        &::after {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 100%;
            height: 1px;
            background: $mystic;
        }
    }

    &__item {

        &:nth-of-type(1) {
            grid-column: col / span 1;
            grid-row: row;
        }

        &:nth-of-type(2) {
            grid-column: col 2 / span 3;
            grid-row: row;
            text-align: right;
        }

        &:nth-of-type(3) {
            grid-column: col / span 4;
            grid-row: row 2;
            display: flex;
        }

        &:nth-of-type(4) {
            grid-column: col / span 1;
            //grid-row: row / span 2;
        }

        &:nth-of-type(5) {
            grid-column: col 2 / span 3;
            //grid-row: row / span 2;
            text-align: right;
        }

        &--centered {
            position: relative;

            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 5%;
                height: 2px;
                background: $brightturquoise;
            }

            &::after {
                position: absolute;
                content: '';
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 5%;
                height: 2px;
                background: $brightturquoise;
            }
        }
    }

    &__circle {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 224px;
        height: 224px;
        margin: auto;
        background: $white;
        border-radius: 50%;
        border: 1px solid $aquahaze;
        box-shadow: inset 0px 0px 0px 5px rgba(255,255,255,1), inset 0px 0px 0px 6px rgba(11, 213, 214, 1);
        text-align: center;
    }

    &__count {
        font-family: $main-font-black, Arial, sans-serif;
        font-size: 48px;
        color: $brightturquoise;    //$curiousblue;
    }

    &__text {
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 15px;
        color: $mineshaft;
    }
}

@media(min-width: $tablet) {
    .c-num-statistics {
        padding-bottom: 48px;

        &__title {
            position: relative;
            font-size: 32px;

            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 46px;
                height: 2px;
                background: $persiangreen;
            }
        }

        &__text {
            font-size: 17px;
        }

        &__count {
            font-size: 69px;
        }

        &__circle {
            width: 305px;
            height: 305px;
        }

        &__body {
            grid-template-areas: "a b" "c d";
        }

        &__item {
            height: 153px;

            &:nth-of-type(1) {
                grid-area: a;
                align-self: start;
            }

            &:nth-of-type(2) {
                grid-area: b;
                align-self: start;
            }

            &:nth-of-type(4) {
                grid-area: c;
                align-self: end;
            }

            &:nth-of-type(5) {
                grid-area: d;
                align-self: end;
            }

            &--centered {
                display: flex;
                height: 305px;
                position: relative;
                top: 77px;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                z-index: 2;

                &::before,
                &::after {
                    width: 11.26%;
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-num-statistics {
        padding-top: 55px;
        padding-bottom: 70px;

        &__header {
            margin-bottom: 60px;
        }

        &__title {
            font-size: 35px;
        }

        &__body {
            grid-template-rows: [row] 206px [row] 206px [row];
        }

        &__text {
            font-size: 20px;
        }

        &__count {
            font-size: 90px;
        }

        &__circle {
            width: 412px;
            height: 412px;
        }

        &__item {
            height: 206px;

            &--centered {
                display: flex;
                top: 0;
                height: 412px;

                &::before,
                &::after {
                    width: 7%;
                }
            }
        }
    }
}