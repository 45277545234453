.c-comfortable {
    padding-bottom: 25px;
    background-color: #128d9b;
    /* IE9, iOS 3.2+ */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjEwMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMxNzllYTgiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzBjN2I4ZSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
    background-image: -webkit-gradient(linear, 0% 100%, 100% 0%,color-stop(0, rgb(23, 158, 168)),color-stop(1, rgb(12, 123, 142)));
    /* Android 2.3 */
    background-image: -webkit-repeating-linear-gradient(bottom left,rgb(23, 158, 168) 0%,rgb(12, 123, 142) 100%);
    /* IE10+ */
    background-image: repeating-linear-gradient(to top right,rgb(23, 158, 168) 0%,rgb(12, 123, 142) 100%);
    background-image: -ms-repeating-linear-gradient(bottom left,rgb(23, 158, 168) 0%,rgb(12, 123, 142) 100%);

    &__title {
        position: relative;
        padding: 8px 0 20px;
        margin: 0 0 25px;
        text-align: left;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 25px;
        line-height: 35px;
        color: $white;
        border-radius: 4px 4px 0 0;

        &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 46px;
            height: 2px;
            background: $white;
        }
    }

    &__paragraph {
        margin-bottom: 20px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 20px;
        line-height: 30px;
        color: $white;
    }

    &__subtitle {
        padding: 20px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 20px;
        color: $white;
        text-align: center;
    }

    &__video {
        position:relative;
        padding-bottom:56.25%;
        padding-top:30px;
        margin-bottom: 21px;
        height:0;
        border-radius: 8px;
        overflow:hidden;

        &--vertical {
            padding-bottom: 150%;
        }

        * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__btn-play {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        transition: all 0.3s;
        background: rgba(20, 42, 43, 0.3) url('../img/content/poster.jpeg') no-repeat center / cover;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70px;
            height: 70px;
            transform: translateX(-50%) translateY(-50%);
            filter: invert(0);

            &:hover {
                filter: invert(10%);
            }
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    &__btn {
        min-width: 145px;
        box-shadow: 0px 3px 12px 0px rgba(9, 95, 96, 0.25);

        &--store {
            margin-right: 10px;
            background: $gablegreen url('../img/general/app-store.png') no-repeat center;

            &:hover {
                background: $gablegreen url('../img/general/app-store.png') no-repeat center;
            }
        }

        &--play {
            background: $gablegreen url('../img/general/google-play.png') no-repeat center;

            &:hover {
                background: $gablegreen url('../img/general/google-play.png') no-repeat center;
            }
        }

        &:hover {
            background-color: $gablegreen;
        }
    }

    &__download {
        text-align: center;

        .c-button {
            min-width: auto;
            padding-left: 33px;
            padding-right: 33px;
        }
    }

    &__image {
        grid-column: col;
        grid-row: row / span 3;
        margin: 0 0 21px;
        border-radius: 5px;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    &--automated_processes {
        padding-bottom: 20px;

        .c-comfortable {

            &__title {
                padding: 8px 0;
                margin-bottom: 5px;
                font-family: $main-font-light, Arial, sans-serif;
                font-weight: normal;
                font-size: 25px;
                line-height: 35px;
                text-align: left;

                &::after {
                    content: none;
                }
            }

            &__img {
                max-width: 100%;
                width: 100%;
                height: auto;
                margin-bottom: 22px;
                border-radius: 5px;
            }

            &__download {
                text-align: center;
            }
        }

        .c-button {
            padding: 14px 24px;
            box-shadow: 0px 3px 5px 0px rgba(50, 102, 101, 0.17),
                        0px 3px 12px 0px rgba(9, 95, 96, 0.25);
        }
    }
}

@media(min-width: $tablet) {
    .c-comfortable {
        padding-bottom: 0;

        &__title {
            padding: 22px 0 15px;
            margin-bottom: 13px;
            font-size: 34px;
            line-height: 44px;
            text-align: left;
        }

        &__paragraph {
            margin-bottom: 27px;
            font-size: 28px;
            line-height: 40px;
        }

        &__subtitle {
            padding-top: 30px;
            padding-bottom: 30px;
            font-size: 23px;
        }

        &__video {
            order: 1;
            margin-bottom: -40px;
        }

        &__download {
            margin-bottom: 26px;
            text-align: left;
        }

        &__image {
            order: 1;
            margin: 0 0 -40px;
        }

        .c-container {
            display: flex;
            flex-direction: column;
        }

        &--automated_processes {
            //padding-bottom: 36px;

            .c-container {
                /*display: grid;
                grid-template-areas:
                    'a b'
                    'a c';
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 50px;*/
                display: flex;
                flex-direction: column;
            }

            .c-comfortable {
                &__img {
                    /*grid-area: a;
                    max-width: calc(100% + 20px);
                    width: calc(100% + 20px);
                    height: 265px;
                    margin-left: -20px;*/
                    margin-bottom: -40px;
                    order: 1;
                }

                &__title {
                    /*padding: 90px 20px 20px 0;
                    grid-area: b;*/
                    padding: 45px 0 38px;
                    margin-bottom: 0;
                    font-size: 34px;
                    line-height: 44px;
                    text-align: left;

                    &::after {
                        left: 0;
                        transform: none;
                    }
                }

                &__download {
                    margin-bottom: 56px;
                    text-align: left;
                    /*grid-area: c;
                    margin-bottom: 0;*/
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-comfortable {
        &__title {
            padding-top: 17px;
            font-size: 40px;
            line-height: 50px;
            text-align: left;

            &::after {
                transform: none;
            }
        }

        &__paragraph {
            max-width: 500px;
            margin-bottom: 22px;
            font-size: 30px;
        }

        &__subtitle {
            padding-left: 0;
            text-align: left;
        }

        &__video {
            grid-column: col;
            grid-row: row / span 3;
            height: 400px;
            margin-top: -50px;
            margin-bottom: 50px;
        }

        &__wrapper {
            justify-content: flex-start;
        }

        &__download {
            margin-bottom: 50px;

            .c-button {
                padding: 14px 33px;
            }
        }

        &__image {
            margin: -50px 0 50px;
            order: 0;
        }

        .c-container {
            display: grid;
            grid-template-areas: "a b" "a c" "a d";
            grid-template-columns: [col] 630px [col] 1fr [col];
            grid-template-rows: [row] auto [row] auto [row];
            grid-column-gap: 40px;
        }

        &--automated_processes {

            .c-comfortable {
                &__img {
                    grid-area: a;
                    height: 400px;
                    margin-top: -50px;
                    margin-bottom: 50px;
                }

                &__title {
                    grid-area: b;
                    margin-bottom: 57px;
                    padding-top: 68px;
                    padding-bottom: 0;
                    font-size: 40px;
                    line-height: 50px;
                }

                &__download {
                    padding-left: 0;
                    margin-bottom: 50px;
                }
            }

            .c-container {
                display: grid;
                grid-template-areas: "a b" "a c";
                grid-template-columns: 630px 1fr;
                grid-template-rows: auto 1fr;
                grid-column-gap: 35px;
            }

            .c-carousel {
                &--app {
                    grid-area: c;

                    .c-button {
                        width: 223px;
                    }
                }
            }
        }
    }
}

/** ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */
.c-comfortable__video--vertical,
.c-comfortable__video.c-comfortable__video--vertical {
    padding-bottom: 150%;
}
/** end ДОБАВЛЕННЫЕ СТИЛИ, не выпиливать!!! */