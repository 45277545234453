.c-currentapp {
    padding: 24px 0 30px;

    &__title {
        position: relative;
        padding: 0 0 57px;
        margin: 0;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 25px;
        color: $black;

        &::after {
            position: absolute;
            content: '';
            bottom: 36px;
            left: 0;
            width: 46px;
            height: 2px;
            background: $persiangreen;
        }
        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__subtitle {
        margin-bottom: 22px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 15px;
        line-height: 21px;
        color: $mineshaft;
    }

    &__filter {
        padding: 20px 22px 30px;
        margin-bottom: 32px;
        background: $aquahaze;
        box-shadow: 0px 0px 25px 0px rgba(197, 204, 203, 0.4);

        .table {
            margin-bottom: 0;
            table-layout: fixed;

            tr {
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }

            td {
                width: 100%;
                padding: 0 0 12px 0;
                border: none;

                &:last-of-type {
                    padding-bottom: 0;
                }
            }

            .btn {
                display: block;
                margin: 0 auto;
            }
        }

        .form-control,
        .multiselect {
            font-family: $main-font-light, Arial, sans-serif;
            font-size: 15px;
            line-height: 21px;
            color: $black;
        }

        .multiselect {
            max-width: 100%;
            height: 46px;
            text-align: left;
            border-radius: 0;
            border-color: $mystic;
            background: $white;
            padding: 5px 40px 5px 20px;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::after {
                width: 40px;
                border: none;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                background: transparent url('../img/general/select.svg') no-repeat center right 15px;
            }

            &-native-select {

                .btn-group {
                    width: 100%;
                }
            }

            &-container {
                width: 100%;
                border: none;

                a {
                    display: block;
                }

                .checkbox {
                    position: relative;
                    display: block;
                    padding: 6px 20px 6px 50px;
                    font-family: $main-font-light, Arial, sans-serif;
                    font-size: 15px;
                    line-height: 21px;
                    color: $mineshaft;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: $mystic;

                    &:hover {
                        background: $breakerbay;
                        color: $white;

                        &::before {
                            background: transparent;
                            background-size: 9px 7px;
                            border-color: $white;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 20px;
                        transform: translateY(-50%);
                        width: 15px;
                        height: 15px;
                        background: $white;
                        border: 1px solid $breakerbay;
                        border-radius: 3px;
                    }
                }

                input[type="checkbox"] {
                    display: none;
                }
            }

            &-selected {

                &-text {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    padding: 5px;
                    text-overflow: ellipsis;
                }

                .checkbox {
                    background: $breakerbay;
                    color: $white;

                    &:hover {

                        &::before {
                            background: transparent url('../img/general/check-white.png') no-repeat center;
                            background-size: 9px 7px;
                        }
                    }

                    &::before {
                        background: transparent url('../img/general/check-white.png') no-repeat center;
                        background-size: 9px 7px;
                        border-color: $white;
                    }
                }
            }
        }
    }

    &__table {

        .dataTable {
            box-shadow: 0px 0px 25px 0px rgba(197, 204, 203, 0.4);

            &.no-footer {
                border: none;
            }

            & > tbody {

                .parent {

                    .c-text {

                        &--bold {
                            margin-bottom: 6px;
                        }

                        &--light {
                            margin-bottom: 0;
                        }
                    }
                }

                & >tr.child {
                    padding: 0;

                    .child {
                        padding: 0 0 25px;
                    }

                    ul.dtr-details {
                        display: grid;
                        grid-template-areas: 'rates rates' 'cargo cargo' 'telephone telephone' 'bell bell';
                        grid-row-gap: 15px;
                        font-size: 0;

                        & > li {
                            /*display: flex;
                            flex-direction: column;
                            align-items: center;*/
                            display: grid;
                            justify-items: center;
                            align-items: flex-start;
                            border: none;

                            span {

                                &.dtr-title {
                                    display: none;
                                }

                                &.dtr-data {
                                    width: 100%;

                                    .c-text {

                                        &--bold {
                                            margin-bottom: 6px;
                                        }

                                        &--light {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }

                            &:nth-of-type(1) {
                                grid-area: cargo;
                            }

                            &:nth-of-type(2) {
                                grid-area: rates;
                            }

                            &:nth-of-type(3) {
                                grid-area: telephone;
                            }

                            &:nth-of-type(4) {
                                grid-area: bell;
                            }
                        }

                        .c-text {

                            &--bold {

                            }

                            &--light {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-success {
        width: 160px;
        padding: 11px;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 16px;
        color: $white;
        background: $malachite;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 3px 12px 0px rgba(9, 95, 96, 0.25),
                    0px 3px 5px 0px rgba(50, 102, 101, 0.17);
    }

    .table {

        &.dataTable {
            table-layout: fixed;

            thead {
                position: relative;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 89px;
                    content: 'Активные заявки';
                    font-family: $main-font-bold, Arial, sans-serif;
                    font-size: 17px;
                    line-height: 85px;
                    color: $white;
                    text-align: center;
                    text-transform: uppercase;
                    vertical-align: middle;
                    background: $breakerbay;
                }
            }

            tbody td {
                padding: 30px 25px;
                font-family: $main-font-light, Arial, sans-serif;
                font-size: 16px;
                color: $black;
            }

            &.dtr-column>tbody>tr {
                //position: relative;

                &.parent {

                    &>td.control {
    
                        &::before {
                            transform: translateY(-14px);
                        }
                    }
                }

                &>td.control {
                    //position: static;

                    &::before {
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: auto;
                        height: 6px;
                        width: 200%;
                        transform: translateY(-14px) rotate(180deg);
                        transition: 0.5s transform;
                        margin: 0;
                        content: url('../img/general/select.svg');
                        font-size: 0;
                        background: transparent;
                        border: none;
                        border-radius: 0;
                        box-shadow: none;
                    }
                }
            }
        }

        th,
        &.dataTable.display tbody td {
            border: none;
            vertical-align: top;

            &:focus {
                outline: none;
            }
        }

        &.dataTable.display tbody tr {
            &.odd {
                background: $white;

                & + tr.child {
                    background: $white;

                    &:hover {
                        background: $white !important;
                    }
                }
            }

            &.even {
                background: $aquahaze;

                & + tr.child {
                    background: $aquahaze;

                    &:hover {
                        background: $aquahaze !important;
                    }
                }
            }
        }

        th {
            padding: 32px 18px;
            font-family: $main-font-bold, Arial, sans-serif;
            font-size: 17px;
            color: $white;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
            background: $breakerbay;
        }
    }
}

.c-select {
    border: none;
    //background: $porcelain;
    height: 46px;
    padding: 0;

    &-wrap {
        .jq-selectbox__select {
            border-color: #cc8062;
        }

        &~.invalid-feedback {
            display: block;
        }
    }
}

/* select-box */
.jq-selectbox {
    font-family: $main-font-light, Arial, sans-serif;

    &.focused {

        .jq-selectbox {
            &__select {
                height: 100%;
                border-color: $geyser;
            }
        }
    }

    &.opened {
        .jq-selectbox {
            &__trigger {
                transform: scale(-1);
            }
        }
    }

    &__select {
        height: 100%;
        border-radius: 0;
        border-color: $mystic;
        background: $white;
        padding: 5px 40px 5px 20px;
        box-shadow: none;


        &:active,
        &:focus,
        &:hover {
            box-shadow: none;
            background: $white;
        }
    }

    /* arrow */
    &__trigger {
        border: none;
        background: transparent url(../img/general/select.svg) no-repeat center right 15px;
        width: 40px;

        &-arrow {
            border: none;
        }
    }

    /* list */
    &__dropdown {
        bottom: 4px!important;
        top: auto !important;
        z-index: -1;
        transform: translateY(100%);
        //background: $porcelain;
        border-radius: 0 0 4px 4px;
        border: none;
        box-shadow: none;

        li {
            padding: 6px 20px 5px;
            font-family: $main-font-light, Arial, sans-serif;
            font-size: 15px;
            line-height: 21px;
            color: $mineshaft;
            background: $mystic;
            border-bottom: 1px solid $white;

            &:hover,
            &.selected,
            &.sel {
                background: $breakerbay;
                color: $white;
            }
        }
    }

    .placeholder {
        //color: $tower-gray;
        font-family: $main-font-light, Arial, sans-serif;
    }
}

@media(min-width: $tablet) {
    .c-currentapp {
        padding-top: 40px;

        &__title {
            padding: 0 0 67px;
            font-size: 32px;

            &::after {
                bottom: 40px;
            }
        }

        &__filter {
            padding: 20px 40px 30px;
            margin-bottom: 37px;

            .table {

                tr {
                    display: grid;
                    grid-template-areas:
                        'one two'
                        'three four';
                    grid-column-gap: 40px;
                    grid-row-gap: 22px;
                    grid-template-columns: 1fr 1fr;
                }

                td {
                    padding-bottom: 0;

                    &:nth-of-type(1) {
                        grid-area: one;
                    }

                    &:nth-of-type(2) {
                        grid-area: two;
                    }

                    &:nth-of-type(3) {
                        grid-area: three;
                    }

                    &:nth-of-type(4) {
                        grid-area: four;
                    }
                }

                .btn {
                    margin: 0;
                }
            }
        }

        .table {

            &.dataTable {

                thead {

                    &::before {
                    }
                }

                & > tbody {
                    & > tr.child {
                        ul.dtr-details {
                            display: grid;
                            grid-template-areas: 'rates telephone bell';
                            grid-template-columns: 1fr 1fr 1fr;

                            li {

                                &:nth-of-type(1) {
                                    padding-top: 0.5em;
                                    grid-area: rates;
                                }

                                &:nth-of-type(2) {
                                    grid-area: telephone;
                                }

                                &:nth-of-type(3) {
                                    grid-area: bell;
                                }
                            }
                        }
                    }
                }

                &.dtr-column>tbody>tr {

                    &.parent {

                        &>td.control {

                            &::before {

                            }
                        }
                    }

                    &>td.control {

                        &::before {
                            width: 300%;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 1024px) {
    .c-currentapp {
        .table {

            &.dataTable {

                tbody td {
                    .c-text--bold {
                        //display: none;
                    }
                }
            }
        }
    }
}

@media(min-width: 1025px) {
    .c-currentapp {
        .table {

            &.dataTable {

                &.dtr-column>tbody>tr>td.control::before,
                &.dtr-column>tbody>tr>th.control::before {
                    //content: none;
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-currentapp {
        padding-top: 71px;

        &__title {
            padding: 0 0 67px;
            margin-bottom: 23px;
            font-size: 40px;
            line-height: 50px;

            &::after {
                bottom: 40px;
            }
        }

        &__filter {
            padding: 20px 22px 30px;
            margin-bottom: 76px;

            .table {

                tr {
                    display: table-row;
                }

                td {
                    padding-bottom: 0;

                    &:nth-of-type(1) {
                        width: 29%;
                        padding-right: 25px;
                    }

                    &:nth-of-type(2) {
                        width: 29%;
                        padding-right: 25px;
                    }

                    &:nth-of-type(3) {
                        width: 29%;
                        padding-right: 28px;
                    }

                    &:nth-of-type(4) {
                        width: 160px;
                    }
                }
            }
        }

        &__table {

            .table {

                tr {

                    .c-text--light,
                    .c-contacts__text {
                        font-size: 16px;
                        margin: 0;
                    }
                }
            }
        }

        .table {

            th {
                padding: 19px 18px;
            }

            &.dataTable {
                table-layout: auto;

                thead {
                    position: relative;

                    &::before {
                        content: none;
                    }
                }

                tbody td {
                    .c-text--bold {
                        display: none;
                    }
                }

                &.display tbody td {
                    vertical-align: middle;

                    &.control {
                        cursor: default;
                    }
                }

                &.dtr-column>tbody>tr>td.control::before,
                &.dtr-column>tbody>tr>th.control::before {
                    content: none;
                }
            }
        }
    }
}