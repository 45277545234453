/* Exo2 */
@font-face {
  font-family: 'Exo2-Light';
  src: local('Exo2-Light'), local('Exo2-Light'), url('../fonts/Exo2/exo2light.woff2') format('woff2'), url('../fonts/Exo2/exo2light.woff') format('woff'), url('../fonts/Exo2/exo2light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2-Regular';
  src: local('Exo2-Regular'), local('Exo2-Regular'), url('../fonts/Exo2/exo2.woff2') format('woff2'), url('../fonts/Exo2/exo2.woff') format('woff'), url('../fonts/Exo2/exo2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2-Medium';
  src: local('Exo2-Medium'), local('Exo2-Medium'), url('../fonts/Exo2/exo2medium.woff2') format('woff2'), url('../fonts/Exo2/exo2medium.woff') format('woff'), url('../fonts/Exo2/exo2medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2-SemiBold';
  src: local('Exo2-SemiBold'), local('Exo2-SemiBold'), url('../fonts/Exo2/exo2semibold.woff2') format('woff2'), url('../fonts/Exo2/exo2semibold.woff') format('woff'), url('../fonts/Exo2/exo2semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2-Bold';
  src: local('Exo2-Bold'), local('Exo2-Bold'), url('../fonts/Exo2/exo2bold.woff2') format('woff2'), url('../fonts/Exo2/exo2bold.woff') format('woff'), url('../fonts/Exo2/exo2bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
    font-family: 'Exo2-Black';
    src: local('Exo2-Black'), local('Exo2-Black'), url('../fonts/Exo2/exo2black.woff2') format('woff2'), url('../fonts/Exo2/exo2black.woff') format('woff'), url('../fonts/Exo2/exo2black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

/* OpenSans */
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), local('OpenSans'), url('../fonts/OpenSans/opensans.woff2') format('woff2'), url('../fonts/OpenSans/opensans.woff') format('woff'), url('../fonts/OpenSans/opensans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}