.c-file {
    padding: 12px 0;

    &__input {

    }

    &__label {
        display: flex;
        align-items: center;
        font-family: $main-font-light, Arial, sans-serif;
        cursor: pointer;
        margin-bottom: 0;
    }
}