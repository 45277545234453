.c-header {
    position: fixed;
    width: 100%;
    z-index: 100;
    background: $blacksqueeze;
    box-shadow: 0px 2px 19px 0px rgba(229, 234, 234, 0.75);

    &__navbar {
        justify-content: space-between;
        padding: 0;
    }

    &__logo {
        position: relative;
        padding: 14px 24px 10px;
        margin-bottom: -16px;
        margin-left: 10px;
        background: $blacksqueeze;
        box-shadow: 0px 2px 19px 0px rgba(229, 234, 234, 0.75);

        &::after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateY(100%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 60px 0 60px;
            border-color: $blacksqueeze transparent transparent transparent;
        }
    }

    &__burger {
        width: 38px;
        height: 38px;
        padding: 14px 10px;
        margin-right: 10px;
        background: $swansdown;
        border: none;

        * {
            display: block;
            width: 18px;
            height: 2px;
            margin-bottom: 2px;
            background: $englishholly;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__login {
        display: flex;
        align-items: center;
        color: $grannysmith;
    }

    &__entry {
        display: block;
        position: relative;
        padding: 0 22px 0 0;
        font-family: $main-font, Arial, sans-serif;
        font-size: 15px;
        color: $black;

        &--active,
        &:hover {
            color: $persiangreen;
        }

        .icon {
            display: none;
        }
    }

    &__registration {
        display: block;
        font-family: $main-font, Arial, sans-serif;
        font-size: 15px;
        color: $black;

        &--active,
        &:hover {
            color: $persiangreen;
        }
    }

    &__signout,
    &__phone-btn {
        position: relative;

        &:focus {
            outline: none;
        }

        &::before {
            position: absolute;
            content: '';
            bottom: -14px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4.5px 5px 4.5px;
            border-color: transparent transparent $swansdown transparent;
        }

        &.collapsed {
            &::before {
                content: none;
            }
        }
    }

    &__phones {
        display: flex;
        align-items: center;
    }

    &__phone {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 22px;
        font-family: $main-font-bold, Arial, sans-serif;
        font-size: 14px;
        color: $black;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__mail {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 22px;
        font-family: $main-font-bold, Arial, sans-serif;
        font-size: 14px;
        color: $black;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__signout,
    &__phone-btn,
    &__icon {
        display: flex;
        flex-basis: 32px;
        flex-shrink: 0;
        flex-grow: 0;
        width: 32px;
        height: 32px;
        display: flex;
        font-size: 20px;
        border-radius: 50%;
        border: 1px solid $breakerbay;
        background: transparent;

        .icon {
            margin: auto;
        }
    }

    &__icon {
        display: none;
    }

    &__collapse {
        flex-grow: 0;
        position: relative;
        z-index: 2;

        &::before {
            position: absolute;
            content: '';
            top: 0;
            right: 29px;
            transform: translateY(-100%) translateX(50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4.5px 5px 4.5px;
            border-color: transparent transparent $swansdown transparent;
        }
    }

    .c-container {
        width: 100%;
    }
}

.c-menu {
    display: block;
    padding: 30px;
    background: $swansdown;

    &__item {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        font-family: $main-font, Arial, sans-serif;
        font-size: 15px;
        color: $black;

        &--active,
        &:hover {
            color: $persiangreen;
        }
    }
}

@media(max-width: $max-mobile) {
    .c-header {
        &__signout,
        &__phone-btn {

            & + .navbar-collapse {
                position: absolute;
                bottom: 0;
                transform: translateY(100%);
                padding: 26px 25px;
                left: 0;
                right: 0;
                background: $swansdown;
                z-index: 2;
            }
        }

        &__phone-btn {
            & + .navbar-collapse {
                left: auto;
                min-width: 184px;
            }
        }
    }
}

@media(min-width: $tablet) {
    .c-header {
        &__logo {
            margin-left: 20px;
            margin-bottom: -29px;
            padding: 16px 26px;

            &::after {
                border-width: 15px 70px 0 70px;
            }

            img {
                width: 88px;
                height: 68px;
            }
        }

        &__burger {
            margin-right: 20px;
        }

        &__icon {
            display: flex;
            flex-basis: 27px;
            width: 27px;
            height: 27px;
            margin-right: 10px;
        }

        &__signout {
            display: none;

            & + .navbar-collapse {
                display: flex;
            }
        }

        &__entry {
            &::after {
                position: absolute;
                content: '/';
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
            }

            &:hover {
                color: $grannysmith;
            }

            .icon {
                display: inline;
                fill: $grannysmith;
                margin-right: 10px;
            }

            span {
                text-decoration: underline;
            }
        }

        &__entry,
        &__registration {
            font-size: 14px;
            color: $grannysmith;
            text-decoration: underline;

            &:hover {
                color: $grannysmith;
            }
        }

        &__collapse {
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translateY(100%);
        }

        &__phone-btn {
            display: none;

            & + .collapse:not(.show),
            & + .navbar-collapse {
                display: flex;
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-header {
        &__logo {
            margin-left: 0;
            order: -2;
        }

        &__collapse {
            position: static;
            transform: none;
            order: -1;

            &::before {
                content: none;
            }
        }

        &__signout,
        &__icon {
            flex-basis: 29px;
            width: 29px;
            height: 29px;
        }

        &__phone,
        &__mail {
            font-size: 15px;
        }

        &__entry,
        &__registration {
            font-size: 15px;
        }
    }

    .c-menu {
        display: flex;
        align-items: center;
        padding: 0;
        background: none;

        &__item {
            position: relative;
            padding-right: 14px;
            margin-right: 10px;
            margin-bottom: 0;

            &::after {
                position: absolute;
                content: '/';
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                color: $silver;
                font-size: 14px;
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    content: none;
                }
            }
        }
    }
}