.c-carousel {

    &__list {
        text-align: left;
        list-style: disc outside;
        padding: 0 0 0 20px;
        margin-bottom: 35px;

        li {
            margin-bottom: 6px;
            font-family: $main-font-light, Arial, sans-serif;
            font-size: 17px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        margin-bottom: 16px;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 20px;
        color: $white;
        text-transform: uppercase;
    }

    &__text {
        margin-bottom: 110px;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 16px;
        color: $white;
    }

    &__button {
        position: absolute;
        bottom: 76px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
    }

    &__mbutton {
        position: unset;
        bottom: 113px;
        display: block;
        white-space: nowrap;
    }

    &__point {
        width: 5px;
        height: 5px;
        margin: auto;
        background: $blacksqueeze;
        border-radius: 50%;
        border: none;
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(22,138,139,1) 0%,rgba(255,255,255,0) 100%);
    }

    .carousel {

        &-item {
            height: 470px;

            &:nth-of-type(1) {
                background: url('../img/content/slide1.jpg') left 50% bottom;
                background-repeat: no-repeat;
                background-size: cover;
            }

            &:nth-of-type(2) {
                background: url('../img/content/slide2.jpg') left 50% top;
                background-size: cover;

                .carousel-caption {
                    top: 40px;
                }
            }

            &:nth-of-type(3) {
                background: url('../img/content/slide3.jpg') left 50% bottom;
                background-size: cover;

                .carousel-caption {
                    top: 100px;
                }
            }
        }

        &-caption {
            right: 10px;
            left: 10px;
            bottom: 0;
            top: 60px;
            padding: 0;
        }

        &-control-prev,
        &-control-next {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid $blacksqueeze;
            top: auto;
            bottom: 222px;
            z-index: 11;
            cursor: pointer;

            &-icon {
                background: none;
            }
        }

        &-control-prev {
            background: url('../img/general/arrow-back.svg') no-repeat center;
            left: 50%;
            transform: translateX(-62px) translateY(50%);
        }

        &-control-next {
            background: url('../img/general/arrow-next.svg') no-repeat center;
            right: 50%;
            transform: translateX(62px) translateY(50%);
        }

        &-indicators {
            align-items: center;
            bottom: 208px;
            width: 33px;
            margin: 0 auto;

            li {
                display: flex;
                height: 8px;
                padding: 0 4px;
                margin: 0;
                background: transparent;

                &:last-child {
                    margin-right: 0;
                }

                &.active {

                    .c-carousel__point {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    &--app {

        .c-carousel {
            &__title {
                margin-bottom: 35px;
                font-size: 25px;
                line-height: 35px;
                text-align: center;
            }

            &__button {
                display: block;
                padding: 14px 40px;
                width: auto;
                bottom: 64px;
            }

            &__list {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                list-style-type: none;
                text-align: center;

                li {
                    position: relative;
                    display: inline-block;
                    padding-left: 12px;
                    margin-bottom: 4px;
                    font-size: 18px;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 11px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: $ziggurat;
                    }
                }
            }
        }

        .carousel {

            &-item {
                position: relative;
                height: 434px;

                &::before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(90deg, rgba(22, 138, 139, 0.5) 0%, rgba(22, 138, 139, 0.3) 100%);
                }

                &:nth-of-type(1) {
                    background: url('../img/content/slide21.jpg') no-repeat left 60% top / cover;
                }

                &:nth-of-type(2) {
                    background: url('../img/content/slide22.jpg') no-repeat left 68% top / cover;
                }
            }

            &-indicators {
                bottom: 160px;
                opacity: 0;
            }

            &-control-prev,
            &-control-next {
                bottom: 170px;
            }

            &-caption {
                top: 88px;
            }
        }
    }

    &--private_office {

        .carousel {

            &-item {
                height: 435px;

                &:nth-of-type(1) {
                    background: url('../img/content/slide31.jpg') no-repeat left 47% top / cover;
                }
            }
        }

        .c-carousel {

            &__title {
                margin-bottom: 20px;
            }

            &__list {
                //display: block;
                padding: 0 0 0 20px;
                list-style-type: disc;

                li {
                    padding-left: 0;
                    display: list-item;
                    font-size: 17px;
                    //text-transform: uppercase;

                    &::before {
                        content: none;
                    }
                }
            }
        }
    }

    &--tools {
        .c-carousel {

            &__title {
                max-width: 300px;
                margin: 0 auto 37px;
            }

            &__text {
                font-family: $main-font-light, Arial, sans-serif;
                font-size: 18px;
                line-height: 28px;
                max-width: 300px;
                margin: 0 auto;
            }
        }

        .carousel {

            &-caption {
                top: 96px;
            }
        }
    }
}

@media(min-width: $tablet) {
    .c-carousel {

        &__title {
            margin-bottom: 30px;
            font-size: 32px;
        }

        &__text {
            //max-width: 615px;
            margin-bottom: 35px;
            font-size: 19px;
        }

        &__button {
            left: 0;
            bottom: 150px;
            transform: none;
        }

        .carousel {

            &-item {
                height: 531px;

                &:nth-of-type(1) {

                    .carousel-caption {
                        top: 125px;
                    }
                }

                &:nth-of-type(2) {

                    .carousel-caption {
                        top: 120px;
                    }
                }

                &:nth-of-type(3) {

                    .carousel-caption {
                        top: 160px;
                    }
                }
            }

            &-control-prev,
            &-control-next {
                //display: none;
                bottom: 114px;
            }

            &-caption {
                right: 20px;
                left: 20px;
                text-align: left;
            }

            &-indicators {
                bottom: 100px;
            }
        }

        &--app {

            .c-carousel {

                &__title {
                    margin-bottom: 34px;
                    font-size: 38px;
                    line-height: 48px;
                    text-align: left;
                }

                &__list {
                    align-items: flex-start;
                    text-align: left;

                    li {
                        margin-bottom: 11px;
                        font-size: 25px;
                        //line-height: 35px;
                    }
                }

                &__button {
                    width: auto;
                    display: inline-flex;
                    bottom: 113px;
                }
            }

            .carousel {

                &-caption {
                    right: 20px;
                    left: 20px;
                }

                &-item {
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        .carousel-caption {
                            top: 77px;
                        }
                    }

                    &::before {
                        background: linear-gradient(90deg, rgba(22,138,139,1) 0%, rgba(255,255,255,0) 100%);
                    }
                }

                &-indicators {
                    bottom: 95px;
                }

                &-control-prev,
                &-control-next {
                    bottom: 110px;
                }
            }
        }

        &--private_office {

            .carousel {

                &-caption {
                    //max-width: 410px;
                    right: 20px;
                    left: 20px;
                }
            }

            .c-carousel {

                &__list {
                    max-width: 480px;
                    margin-bottom: 43px;

                    li {
                        font-size: 23px;
                        line-height: 35px;
                    }
                }

                &__button {
                    bottom: 122px;
                }
            }
        }

        &--tools {
            .c-carousel {
                &__title {
                    max-width: 600px;
                    margin-left: 0;
                }

                &__text {
                    margin-left: 0;
                    max-width: 400px;
                    font-size: 25px;
                    line-height: 35px;
                }
            }

            .carousel {
                &-item {

                    &:nth-of-type(1) {
                        background: url('../img/content/slide31new.jpg') no-repeat left 26% top/cover;

                        .carousel-caption {
                            top: 98px;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-carousel {

        &__title {
            max-width: 1024px;
            font-size: 35px;
        }

        &__text {
            font-size: 20px;
        }

        &__button {
            bottom: 207px;
        }

        .carousel {

            &-item {
                height: 581px;

                &:nth-of-type(1),
                &:nth-of-type(2) {
                    .carousel {
                        &-caption {
                            top: 150px;
                        }
                    }
                }

                &:nth-of-type(3) {
                    .carousel {
                        &-caption {
                            top: 192px;
                        }
                    }
                }
            }

            &-caption {
                max-width: 1200px;
                margin: 0 auto;
                right: 0;
                left: 0;
            }

            &-indicators {
                width: 40px;

                li {
                    width: 6px;
                    height: 6px;
                    margin-right: 9px;

                    &.active {
                        width: 9px;
                        height: 9px;
                    }
                }
            }

            &-control-prev {
                transform: translateX(-100px) translateY(50%);
            }

            &-control-next {
                transform: translateX(100px) translateY(50%);
            }
        }

        &--app {

            .c-carousel {

                &__title {
                    font-size: 45px;
                    line-height: 61px;
                    margin-bottom: 42px;
                }

                &__list {
                    margin-bottom: 45px;
                    max-width: 515px;

                    li {
                        font-size: 25px;
                    }
                }
            }

            .carousel {
                &-item {

                    &:nth-of-type(1) {
                        background: url('../img/content/slide21.jpg') no-repeat left 60% top / cover;

                        .carousel {
                            &-caption {
                                top: 97px;
                            }
                        }
                    }

                    /*&:nth-of-type(2) {
                        background: url('../img/content/slide22.jpg') no-repeat left 68% top / cover;
                    }*/
                }
            }
        }

        &--private_office {
            .carousel {
                &-item {

                    &:nth-of-type(1) {
                        background: url('../img/content/slide31big.jpg') no-repeat right 20% bottom / cover;

                        .carousel-caption {
                            top: 132px;
                        }
                    }
                }
            }
        }

        &--tools {
            .c-carousel {
                &__title {
                    max-width: 660px;
                }

                &__text {
                    font-family: $main-font-light, Arial, sans-serif;
                    font-size: 25px;
                    line-height: 35px;
                    max-width: 570px;
                }
            }

            .carousel {
                &-item {
                    &:nth-of-type(1) {
                        .carousel-caption {
                            top: 158px;
                        }
                    }
                }
            }
        }
    }
}