// ЦВЕТА
$white: #ffffff;
$black: #000000;
$black1: #010101;

$success: #2ECC71;
$warning: #FCC164;
$danger: #E60000;

$blacksqueeze: #f9fdfd;   // bg page
$swansdown: #d3eded;    // bg burger
$englishholly: #031d06; // line burger
$breakerbay: #559798; // color icon
$persiangreen: #00999a; // active link
$silver: #cccccc; // '/'
$grannysmith: #819999; // color login
$aquahaze: #f4f8f8; // bg modal
$aquahaze2: #f4f9f9;
$mystic: #e8f1f1;   // border field
$malachite: #10cd45;
$mineshaft: #3d3d3d;
$brightturquoise: #0bd5d6;
$cuttysark: #4b6c66;
$ziggurat: #c1e0e0;
$porceline: #f0f4f4;
$geyser: #dbe6e6;
$curiousblue: #1e9dd2;
$gablegreen: #162a31;
$juniper: #698686;
$emperor: #515151;

// РАЗМЕРЫ
$baseFontSize: 15px;

$mobile: 320px;
$max-mobile: 767px;
$tablet: 768px;
$max-tablet: 1199px;
$desctop: 1200px;

// ШРИФТЫ
$main-font-light: 'Exo2-Light';    // 300
$main-font: 'Exo2-Regular'; // 400
$main-font-medium: 'Exo2-Medium';  // 500
$main-font-semi: 'Exo2-SemiBold';    //600
$main-font-bold: 'Exo2-Bold';    // 700
$main-font-black: 'Exo2-Black'; // 900

$second-font: 'OpenSans';   //400
$second-font-bold: '';

$yet-font: '';