// Иконки svg
$pref: project-icon;

.#{$pref}-base {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	background-position: center center;
	background-repeat: no-repeat;
}

.#{$pref}-truck {
	@extend .#{$pref}-base;
	width: 18px;
	height: 11px;
    background-image: url('../img/general/truck.svg');
	background-color: transparent;
	background-size: contain;
}

$pref-sprite: icon;

.#{$pref-sprite}-signout {
	width: 12px;
	height: 15px;
	fill: $breakerbay;
}

.#{$pref-sprite}-phone {
	width: 15px;
	height: 15px;
	fill: $breakerbay;
}

.#{$pref-sprite}-close {
	width: 30px;
	height: 30px;
	fill: $aquahaze;
}

.#{$pref-sprite}-lock {
	width: 10px;
	height: 13px;
	fill: $grannysmith;
}

.#{$pref-sprite}-arrow {
	width: 13px;
	height: 7px;
	fill: $black;
}

.#{$pref-sprite}-arrow2 {
	width: 17px;
	height: 11px;
	//fill: $black;
}

.#{$pref-sprite}-down {
	width: 7px;
	height: 11px;
	fill: $persiangreen;
}

.#{$pref-sprite}-address {
	width: 17px;
	height: 17px;
	fill: $breakerbay;
}

.#{$pref-sprite}-mail {
	width: 15px;
	height: 11px;
	fill: $breakerbay;
}

.#{$pref-sprite}-clip {     /* Скрепка */
    width: 13px;
    height: 15px;
    fill: $breakerbay;
    margin-right: 9px;
}
.#{$pref-sprite}-private_office1 {
	width: 82px;
	height: 82px;
	//fill: $breakerbay;
	margin: 0 auto;
}

.#{$pref-sprite}-private_office2 {
	width: 73px;
	height: 85px;
	margin: 0 auto;
}

.#{$pref-sprite}-private_office3 {
	width: 64px;
	height: 82px;
	margin: 0 auto;
}

.#{$pref-sprite}-private_office4 {
	width: 100px;
	height: 92px;
	margin: 0 auto;
}

.#{$pref-sprite}-private_office5 {
	width: 90px;
	height: 92px;
	margin: 0 auto;
}

.#{$pref-sprite}-private_office6 {
	width: 92px;
	height: 92px;
	margin: 0 auto;
}