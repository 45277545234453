.c-form {

    &__one-of-two {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        & > * {
            flex-basis: calc((100% - 35px)/2);
            flex-grow: 0;
            flex-shrink: 0;

            &:nth-last-of-type(2),
            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
        }
    }

    &__group {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__label {
        display: block;
        margin-bottom: 13px;
        font-size: 14px;
        line-height: 17px;
        //color: $charade;
    }

    &__field {
        width: 100%;
        min-height: 53px;
        padding: 13px 15px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 15px;
        line-height: 17px;
        color: $black;
        background: $white;
        border: 1px solid $mystic;
        box-sizing: border-box;
        border-radius: 0;
        white-space: nowrap; /* Отменяем перенос текста */
        overflow: hidden; /* Обрезаем содержимое */
        text-overflow: ellipsis; /* Многоточие */

        @include placeholder {
            color: $black;
        }
    }

    &__quick-pick {
        padding: 5px 0 21px;
    }

    &--invalid {
        .c-button[disabled] {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
}

@media (max-width: 767px) {
    .c-form {
        &__one-of-two {
            display: block;
    
            & > * {
    
                &:nth-last-of-type(2) {
                    margin-bottom: 24px;
                }
            }
        }
    }
}