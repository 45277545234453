.c-usemobileapp {
    background: $aquahaze2;
    border-bottom: 1px solid $mystic;

    &__title {
        padding: 20px;
        margin: 0;
        text-align: center;
        font-family: $main-font-medium, Arial, sans-serif;
        font-size: 21px;
        color: $black;
        border-radius: 4px 4px 0 0;

        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }

    &__opportunities {
        padding: 0 20px 20px;
        margin-bottom: 25px;
        background: $blacksqueeze;
        box-shadow: 0px 0px 25px 0px rgba(197, 204, 203, 0.4); 
    }

    &__subtitle {
        margin: 0 0 14px;
        padding: 15px 0;
        font-family: $main-font-bold, Arial, sans-serif;
        font-size: 16px;
        color: $black;
        border-bottom: 1px solid $mystic;
    }

    &__text,
    &__item {
        display: block;
        margin-bottom: 3px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 15px;
        color: $black;
    }

    &__item {
        position: relative;
        padding-left: 33px;

        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 3px;
            width: 17px;
            height: 17px;
            background: $porceline;
            border-radius: 50%;
        }

        &::after {
            position: absolute;
            content: '';
            left: 4px;
            top: 5px;
            width: 14px;
            height: 11px;
            background: url('../img/general/check-lightgreen.png') no-repeat center;
        }
    }

    &__list {
        &--dark {
            .c-usemobileapp__item {

                &::after {
                    background: url('../img/general/check.png') no-repeat center;
                }
            }
        }
    }

    &__action {
        padding-top: 10px;
        text-align: center;
    }

    &__caption {
        margin-bottom: 20px;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 18px;
        color: $black;
    }

    &__benefits {
        display: none;
    }

    &__benefit {
        flex-basis: calc((100% - 60px)/3);
        padding: 25px;
        background: $blacksqueeze;
        box-shadow: 0px 0px 25px 0px rgba(197, 204, 203, 0.4);

        &-wrap {
            height: 53px;
        }

        &-title {
            margin-bottom: 9px;
            font-family: $main-font, Arial, sans-serif;
            font-size: 16px;
            color: $juniper;
        }

        &-text {
            margin-bottom: 0;
            font-family: $main-font-light, Arial, sans-serif;
            font-size: 16px;
            color: $black;
        }
    }

    &__bg {
        display: none;

        img {
            border-radius: 5px;
        }
    }

    &--exceptional-opportunities,
    &--app {
        background: $blacksqueeze;
        border: none;

        .c-usemobileapp {
            &__title {
                position: relative;
                padding: 26px 0 15px;
                font-family: $main-font-light, Arial, sans-serif;
                font-weight: normal;
                font-size: 25px;
                line-height: 35px;
                color: $black1;
                text-align: left;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: 46px;
                    height: 2px;
                    background: $persiangreen;
                }

                span {
                    color: $black1;
                }
            }

            &__opportunities {
                //padding-top: 340px;
                //background: url('../img/content/digital-platform.jpg') no-repeat top right / auto 320px;
                padding: 0;
                margin-left: -10px;
                margin-right: -10px;
                box-shadow: none;
                background: transparent;
            }

            &__item {
                padding: 14px 50px 15px 45px;
                margin-bottom: 0;
                font-size: 18px;
                line-height: 28px;
                border-bottom: 1px solid $mystic;

                &::before {
                    left: 10px;
                    top: 21px;
                }

                &::after {
                    left: 14px;
                    top: 21px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    border: none;
                }
            }

            &__action {
                padding-top: 14px;
            }

            &__btn {
                padding-left: 30px;
                padding-right: 30px;
            }

            &__caption {
                padding: 0 10px;
                margin-bottom: 0;
                font-family: $main-font-light, Arial, sans-serif;
                font-size: 23px;
                line-height: 30px;
                color: $black;
                text-align: left;
            }
        }
    }

    &--app {

        .c-usemobileapp {
            &__opportunities {
                margin-bottom: 30px;
            }

            &__item {
                border: none;

                &:last-of-type {
                    .c-usemobileapp {
                        &__line {
                            display: none;
                        }
                    }
                }
            }

            &__line {
                position: absolute;
                height: 1px;
                left: 36px;
                right: 0;
                bottom: 0;
                background: $mystic;
            }
        }
    }
}

@media(min-width: $tablet) {
    .c-usemobileapp {
        padding-top: 60px;

        &__title {
            font-size: 32px;
        }

        &__subtitle {
            font-size: 20px;
        }

        &__text,
        &__item {
            margin-bottom: 8px;
            font-size: 16px;
        }

        &__caption {
            font-size: 23px;
        }

        &__opportunities {
            display: grid;
            grid-template-areas: "a b" "c c";
            grid-template-columns: [col] 1fr [col] 1fr [col];
            grid-template-rows: [row] auto [row];
            grid-column-gap: 40px;
            padding-top: 14px;
            padding-bottom: 40px;
            margin-bottom: 0;
        }

        &__action {
            grid-column: col / span 2;
        }

        &__benefits {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            padding-top: 30px;
            padding-bottom: 40px;
        }

        &--app {
            .c-usemobileapp {
                &__title {
                    padding: 36px 0 27px;
                    margin-bottom: 12px;
                    font-size: 34px;
                    line-height: 44px;
                }

                &__opportunities {
                    display: block;
                    padding-top: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 60px;
                }

                &__item {
                    padding: 20px 50px 20px 36px;
                    font-size: 23px;
                    line-height: 28px;

                    &::before {
                        left: 0;
                        top: 31px;
                    }

                    &::after {
                        left: 4px;
                        top: 31px;
                    }
                }

                &__action {
                    padding-top: 25px;
                    font-size: 28px;
                    line-height: 38px;
                }
            }
        }

        &--exceptional-opportunities {

            .c-usemobileapp {

                &__title {
                    padding: 31px 0 27px;
                    margin-bottom: 12px;
                    font-size: 34px;
                    line-height: 44px;
                }

                &__opportunities {
                    /*grid-template-areas:
                    "a b"
                    "c b";*/
                    display: block;
                    padding-top: 20px;
                    margin-left: 0;
                    margin-right: 0;
                }

                &__action {
                    //grid-column: col / span 1;
                    padding-top: 23px;
                    text-align: left;
                }

                &__item {
                    padding: 20px 50px 20px 36px;
                    //margin-bottom: 18px;
                    font-size: 23px;
                    line-height: 28px;

                    &::before {
                        left: 0;
                        top: 27px;
                    }

                    &::after {
                        left: 4px;
                        top: 27px;
                    }
                }
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-usemobileapp {

        &__opportunities {
            grid-template-areas: "a b d" "c c c";
            grid-template-columns: [col] 330px [col] 330px [col] 1fr [col];
            padding-top: 24px;
            padding-bottom: 50px;
            background: $blacksqueeze url('../img/content/mobile-app.jpg') no-repeat top right;
        }

        &__benefits {
            display: grid;
            grid-template-areas: "a b c";
            grid-template-columns: [col] 1fr [col] 1fr [col] 1fr;
            grid-column-gap: 50px;
        }

        &__benefit {
            display: grid;
            grid-template-areas: "a b" "a c";
            grid-template-columns: [col] 50px [col] 1fr [col];
            grid-template-rows: [row] 47px [row];
            padding-top: 30px;
            padding-bottom: 30px;

            &:nth-of-type(1) {
                .c-usemobileapp__img {
                    width: 39px;
                    height: 36px;
                }
            }

            &:nth-of-type(2) {
                .c-usemobileapp__img {
                    width: 40px;
                    height: 35px;
                }
            }

            &:nth-of-type(3) {
                .c-usemobileapp__img {
                    width: 45px;
                    height: 35px;
                }
            }

            &-wrap {
                grid-column: col;
                grid-row: row / span 2;
                height: auto;
            }
        }

        &--app {
            .c-usemobileapp {

                &__bg {
                    //bottom: 132px !important;
                    bottom: auto !important;
                    top: 223px;
                }

                &__caption {
                    font-size: 30px;
                    line-height: 40px;
                }

                &__opportunities {
                    margin-bottom: 180px;
                    margin-top: 66px;
                }
            }
        }

        &--app,
        &--exceptional-opportunities {
            position: relative;
            overflow: hidden;
            //background: url('../img/content/digital-platform.png') no-repeat bottom left 675px / auto 515px;

            .c-usemobileapp {
                &__title {
                    max-width: 850px;
                }

                &__bg {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 56.17%;
                    z-index: 0;
                }

                &__action {
                    position: relative;
                }

                &__opportunities {
                    grid-template-columns: [col] 1fr [col] 1fr [col];
                    max-width: 595px;
                    margin-left: 0;
                    margin-right: 0;
                    padding-top: 0;
                }

                &__item {
                    padding-right: 0;
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    font-size: 23px;
                    line-height: 28px;
                }
            }
        }

        &--app {
            .c-usemobileapp {
                &__title {
                    max-width: 985px;
                    font-size: 40px;
                    line-height: 50px;
                }

                &__item {
                    margin-bottom: 0;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }

                &__opportunities {
                    max-width: 684px;
                }

                &__bg {
                    left: calc(50% + 124px);
                }
            }
        }

        &--exceptional-opportunities {
            .c-usemobileapp {

                &__bg {
                    left: 56.17%;
                }

                &__title {
                    padding-top: 6px;
                    padding-bottom: 15px;
                    margin-bottom: 44px;
                }

                &__item {
                    padding-top: 21px;
                    padding-bottom: 22px;
                    margin-bottom: 0;

                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}