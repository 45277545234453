.c-contacts {
    padding: 24px 0 30px;
    border-bottom: 1px solid $mystic;

    &__title {
        position: relative;
        padding: 0 0 57px;
        margin: 0;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 25px;
        color: $black;

        &::after {
            position: absolute;
            content: '';
            bottom: 36px;
            left: 0;
            width: 46px;
            height: 2px;
            background: $persiangreen;
        }
        span {
            margin-right: 5px;
            color: $persiangreen;
        }
    }


    &__body {

    }

    &__coordinate {
        margin-bottom: 25px;

        &-address,
        &-phone,
        &-mail {
            display: flex;
            align-items: center;
        }

        & > * {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        font-size: 0;
        font-family: $main-font-light, Arial, sans-serif;
        font-size: 15px;
        color: $black;

        br {
            display: none;
        }
    }

    &__icon {
        display: flex;
        flex-basis: 40px;
        flex-shrink: 0;
        flex-grow: 0;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid $persiangreen;

        svg {
            margin: auto;
        }
    }

    &__form {

        &.c-modal {
            .modal-content {
                box-shadow: 0px 0px 25px 0px rgba(197, 204, 203, 0.4);
                background: $aquahaze;
                max-width: 100%;
            }
        }
    }
}

@media(min-width: $tablet) {
    .c-contacts {
        padding-top: 40px;

        &__title {
            padding: 0 0 67px;
            font-size: 32px;

            &::after {
                bottom: 40px;
            }
        }

        &__body {
            background: url('../img/general/map.png') no-repeat left bottom / cover;
        }

        &__coordinate {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 67px;

            & > * {
                max-width: 200px;
                margin-bottom: 0;
            }
        }

        &__text {
            br {
                display: block;
            }
        }

        &__form {
            &.c-modal {
                .modal-content {
                    max-width: 310px;
                    margin-right: 0;
                }
            }
        }

        &--app {
            padding-top: 95px;

            .c-contacts {
            }
        }
    }
}

@media(min-width: $desctop) {
    .c-contacts {
        padding-top: 118px;

        &__title {
            padding: 0 0 67px;
            font-size: 40px;
            line-height: 50px;

            &::after {
                bottom: 40px;
            }
        }

        &__body {
            display: flex;
            align-items: flex-start;
            background-size: contain;
        }

        &__coordinate {
            flex-grow: 1;
        }

        &__form {
            padding-top: 20px;
            flex-basis: 400px;
        }

        &--app {
            padding-top: 80px;
        }
    }
}